import { getTrips, getStations } from "./src/api";
import { initMap } from "./src/map";
import { initStats } from "./src/stats";
import { initUi } from "./src/ui";
import { getCurrentYear } from "./src/util";

const year = getCurrentYear();

Promise.all([getTrips(year), getStations()]).then(([trips, stations]) => {
  initMap(trips, stations);
  initStats(trips, stations);
  initUi();
});
