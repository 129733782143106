import { getCurrentYear } from "./util";

export const initUi = () => {
  const yearSelector = document.getElementById("year-selector");
  yearSelector.addEventListener("change", (event) => {
    window.location.href = `?year=${event.target.value}`;
  });
  const currentYear = getCurrentYear();
  yearSelector.value = currentYear;
};
