import Feature from "ol/Feature";
import Map from "ol/Map";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Icon, Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { OSM } from "ol/source";
import Overlay from "ol/Overlay";
import dotIcon from "../public/dot.svg";

export const initMap = (trips, stations) => {
  const stationsVisited = trips.flatMap((t) => [
    t.departureStation,
    t.returnStation,
  ]);

  const points = stations.map((station) => {
    const point = new Feature({
      geometry: new Point(fromLonLat([station.lon, station.lat])),
      name: station.name,
      id: station.stationId,
    });
    point.setStyle(
      new Style({
        image: new Icon({
          color: stationsVisited.some(
            (visitedStation) => visitedStation.stationId === station.stationId
          )
            ? "green"
            : station.state === "Station off"
            ? "lightgrey"
            : "yellow",
          crossOrigin: "anonymous",
          src: dotIcon,
          scale: 0.5,
        }),
      })
    );
    return point;
  });

  const vectorSource = new VectorSource({
    features: points,
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
  });

  const rasterLayer = new TileLayer({
    source: new OSM(),
  });

  const map = new Map({
    layers: [rasterLayer, vectorLayer],
    target: document.getElementById("map"),
    view: new View({
      center: fromLonLat([stations[0].lon, stations[0].lat - 0.05]),
      zoom: 12,
    }),
  });

  const tooltip = document.getElementById("tooltip");
  const overlay = new Overlay({
    element: tooltip,
    offset: [10, 0],
    positioning: "bottom-left",
  });
  map.addOverlay(overlay);

  function displayTooltip(evt) {
    const pixel = evt.pixel;
    const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
      return feature;
    });
    tooltip.style.display = feature ? "" : "none";
    if (feature) {
      overlay.setPosition(evt.coordinate);
      tooltip.innerHTML = `${feature.get("id")} ${feature.get("name")}`;
    }
  }

  map.on("pointermove", displayTooltip);
};
